import './dashboard.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import img_jubilados from '../img/jubilados_pensionados.png'
import img_nomina from '../img/nominas.png'
import img_cuotas from '../img/cuotas.png'
import img_moras from '../img/moras.png'
import img_sobrevivencias from '../img/portal_sobrevivencias.png'
import img_pp_renap from '../img/pp_renap.png'
import Header from '../header/header';

function Dashboard() {

    const img_style = { borderRadius: '2px 2px 0 0', height: '170px', maxWidth: '100%' };
    const hdr_jubilados = <img src={img_jubilados} alt='img_jubilados' style={img_style} />;
    const hdr_nominas = <img src={img_nomina} alt='img_nomina' style={img_style} />;
    const hdr_cuotas = <img src={img_cuotas} alt='img_nomina' style={img_style} />;
    const hdr_moras = <img src={img_moras} alt='img_nomina' style={img_style} />;
    const hdr_sobrevivencias = <img src={img_sobrevivencias} alt='img_sobrevivencias' style={img_style} />;
    const hdr_renap = <img src={img_pp_renap} alt='img_renap' style={img_style} />;

    return (
        <>
            <Header logo={true} />
            <div className="container-fluid container__dashboard h-100 pt-3 pb-5">
                <div className="row justify-content-center text-center py-3">
                    {/* JUBILADOS */}
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <a href="/jubilados/">
                            <Card header={hdr_jubilados} >
                                <Button label="JUBILADOS Y PENSIONADOS" icon="pi pi-id-card" />
                            </Card>
                        </a>
                    </div>
                    {/* NOMINAS */}
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <a href="/nominas/">
                            <Card header={hdr_nominas} >
                                <div className='carta'>
                                    <Button label="NÓMINAS" icon="pi pi-money-bill" className="" />
                                </div>
                            </Card>
                        </a>
                    </div>
                    {/* CUOTAS */}
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <a href="/cuotas/">
                            <Card header={hdr_cuotas} >
                                <Button label="CUOTAS" icon="pi pi-folder" className="" />
                            </Card>
                        </a>
                    </div>
                    {/* MORAS */}
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <a href="/moras/">
                            <Card header={hdr_moras} >
                                <Button label="MOROSIDAD" icon="pi pi-arrow-up-right" className="" />
                            </Card>
                        </a>
                    </div>
                </div>
                {/* SOBREVIVENCIAS */}
                <div className="row justify-content-center text-center py-3">
                    {/* <div className="col-lg-3 col-md-6 col-sm-12" >
                        <a href="https://planprestaciones.com/" target='_blank'>
                            <Card >
                                <Button label="RECEPCIÓN SOBREVIVENCIAS" icon="pi pi-globe" />
                            </Card>
                        </a>
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-sm-12" >
                        <a href="/sobrevivencias" target="_blank" rel="noreferrer">
                            <Card header={hdr_sobrevivencias} >
                                <Button label="PORTAL DE SOBREVIVENCIAS" icon="pi pi-chart-pie" />
                            </Card>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12" >
                        <a href="/sobrevivencias/consulta_renap" target="_blank" rel="noreferrer">
                            <Card header={hdr_renap} >
                                <Button label="INTEGRACIÓN RENAP" icon="pi pi-database" />
                            </Card>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer">
                <p>&copy; {new Date().getFullYear()} &bull; Procesamiento de Datos | Portal Administrativo</p>
            </div>
        </>
    );
}

export default Dashboard;
