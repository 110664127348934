import './App.css';
import React, { useEffect, useState } from 'react';

import SidebarComponent from './sidebar/sidebar';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/nova-alt/theme.css'

import Rutas from './routes/routes';
import { logout } from './auth/logout';

import loading_gif from './img/loading.gif'

const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Para validar tiempo de sesión
    const expires = localStorage.getItem('expires');
    if (expires) {
      const date_expires = new Date(expires);
      const today = new Date();
      if (date_expires.getTime() < today.getTime()) {
        logout();
      }
    } else {
      logout();
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      <SidebarComponent />
      {
        loading ?
          <div style={{ paddingTop: '300px' }} className='text-center w-100'>
            < img src={loading_gif} alt='loading' style={{ height: '50px' }} />
          </div >
          :
          <Rutas />
      }
    </div >
  );
}

export default App;
