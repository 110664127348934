import './header.css';
import gif_logo from '../img/gif_plan.gif'

function Header() {
    return (
        <div className='container__header' style={{ marginTop: '30px' }}>
            <div className='logo__header' >
                <img src={gif_logo} alt='logo' style={{ height: '230px' }} />
            </div>
            <hr id='hr_header' />
        </div >
    );
}

export default Header;
